import Vue from 'vue'
import VueTouch from 'vue-touch'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import store from './store.js';
import VueSignaturePad from 'vue-signature-pad';
import device from "vue-device-detector";
import browserDetect from "vue-browser-detect-plugin";
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-free/css/all.css';
import $ from 'jquery';
window.$ = $;
// Agrega los iconos a la biblioteca
library.add(far, fas, fab);
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(VueSignaturePad);
import Toasted from 'vue-toasted';
import router from './router';
Vue.use(Toasted, {
    duration: 2300,
    position: 'top-right',
    theme: 'bubble',
    container: '#container'
})

// RUTAS DE CONEXIÓN A LA API
//axios.defaults.baseURL = 'http://localhost/eps/api/public/api/';
//axios.defaults.baseURL = 'http://10.202.1.183/eps/api/public/api/';
//axios.defaults.baseURL = 'http://192.168.1.193/eps/api/public/api/';
//axios.defaults.baseURL = 'http://192.168.1.25/eps/api/public/api/';
//axios.defaults.baseURL = 'http://192.168.0.32/eps/api/public/api/';
//axios.defaults.baseURL = 'http://192.168.0.198/eps/api/public/api/';
//axios.defaults.baseURL = 'https://rosetrade.primarossa.com/api/public/api/';
axios.defaults.baseURL = 'https://pruebasproduccion.primarossa.com/api/public/api/';
//axios.defaults.baseURL = 'https://restauracionrosetrade.primarossa.com/api/public/api/';
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue);
Vue.use(device);
Vue.use(browserDetect);
function routeExists(routePath) {
  return router.options.routes.some(route => route.path === routePath);
}
router.beforeEach((to, from, next) => {
  // Verificar si la ruta existe y redirije por si no existe o continua por si existe
  if (routeExists(to.path)) {
    next();
  } else {
    next('/');
    localStorage.clear();
    store.dispatch('actalizarEstadoMenu', false);
  }
  next();
});


new Vue({
  router,
  store,
  VueTouch,
  render: h => h(App)
}).$mount('#app')
